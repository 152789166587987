/* font-famliy */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap");
@font-face {
	font-family: gotham;
	src: url(../public/fonts/Poppins-Regular.ttf);
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #d9deff;
}

.jc-center{ justify-content: center; }
.ai-center { align-content: center; }

.revItem{  width: 50%;
  background: #00133d;
  border-radius: 33px;
  padding: 31px 20px;
  color: white;
}
.revItem:first-child{
  margin-right: 10px;
}
.revItem:last-child{
  margin-left: 10px;
}

.revC > h3{
  color: white;
  font-size: 32px;
  margin-bottom: -4px;
}
.revC > p{
  font-size: 12px;
    float: right;
    margin-top: 0px;
}
.pd-bt-10{ padding: 2%;}

body {    background: #fdfdfd; font-family:  gotham, sans-serif;font-size: 16px;line-height: 24px;font-weight: 500;color: #555555;}
a:hover {text-decoration: none;}
.btn-primary{
  background: rgba(0, 37, 118, 1);
	border-color: rgba(0, 37, 118, 1);
	color: white !important;
	border-radius: 5px;
  box-shadow: 0px 0px 3px 1px #cbcbcb;
}
.navbar-collapse{
  margin-left: 3rem;
}
.mobile_nav > ul:first-child, .mobile_nav > ul:nth-child(2) {
  margin: 0 20px;
}
.pagination-nav .active{
  border-bottom: 1px solid black;
    border-radius: 0px;
    color: #00133d !important;
    font-weight: 500;
}
.box-shadow {
  border-radius: 5px;
  /* border: 1px solid black; */
  padding: 10px;
  box-shadow: 0px 0px 5px 0px #b6b5b5;
}
.box-shadow > h2{
  font-size: 25px;
    font-weight: normal;
    color: #8b8b8b;
    background: white;
    /* margin-bottom: 28px; */
}
.dropdown-item:focus, .dropdown-item:hover{
  opacity: 0.5;
    background: none;
    color: inherit;
}
.formatheader{
  width: 100%;
  /* position: absolute; */
  background: #00133d;
  padding: 20px 19% 6% 19%;
  border: none;
  margin-top: 0px !important;
  border-radius: 0px;
}
.formatheader h3 {
  color:  #AACCFF;
}
.formatheader p {
  color: #cecece;
    font-size: 12px;
}
.customNav{

}
.customNav li.dropdown{
  margin-left: 10px;
    position: static;
    margin-right: 21px;
}
.single > a{
  color: white;
}
.buyerHeader{
  justify-content: flex-start;
}

.buyerHeader ul{
 width: 200px;
}

.buyerHeader .dropdown-item{
  padding: 10px 0px;
  color: #f3f3f3;
}

.hidden{
  visibility: hidden;
}
.colorHidden{
  visibility: hidden;
  background-color: #000;
  color: white;
}
.gender-badge{
  border: 1px solid black;
    border-radius: 10px;
    font-size: 15px;
    padding: 3px 14px;
    text-align: center;
    /* color: #00133d; */
}
.box-shadow > h2 > p{
  margin-bottom: 30px;
}
.dropdown-toggle {
  color: #ffffff
}
/* .pagination-design{
  font-weight: 100;
  color: #898989;
  justify-content: space-evenly;
 
} */
/* .pagination-design div,p{ padding-top: 7px;}
.pagination-nav > ul {     --bs-nav-link-padding-y: 0px !important; }
.pagination-nav > ul > li {border-right: 2px solid #bebebeb3;}
.pagination-nav > ul > li > a {color:#bebebeb3;}

.pagination-nav > ul > li > a.active {background:#1976d240;} */

.width1300{ max-width:1300px !important;}
.btn-primary:hover{
	background-color: #2d3486;
	border-color: #2d3486;
	color: white;
	border-radius: 5px;
}
.form-check-input:checked {
  background-color: rgba(0, 37, 118, 1);
  border-color:rgba(0, 37, 118, 1);
}
.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #111111 !important;
  border-color: #111111 !important;
}
.text-light-green{
  color:#2F8380;
}
.campaignlist{
  margin: 10px 0px 30px 0px;
  padding: 20px;
  /* border: 1px solid #3f338b7a; */
  box-shadow: 0px 0px 2px 0px #96969670
  /* border-radius: 20px; */
}
.campaignlist .Content > p, .campaignlist .SideContent > p{     margin-bottom: 0rem; color: #3F338B; font-size: 16px; }
.campaignlist .SideContent > h5{ color: #3F338B; }
.c-desc, .c-desc~p{ color:grey !important}
.campaignlist .SideContent {text-align: right; }
.btn-c-edit{ justify-content: flex-end; padding: 10px 0px;}
.btn-primary-outline{border: 1px solid #0f1451f5;color: #0f1451;}
.btn-danger-outline{border: 1px solid #bb0018f5;color: #bb0018f5;}
.btn-primary-outline:hover, .btn-danger-outline:hover{opacity: 0.5; border: 1px solid #5e5e5ef5;color: #686868f5;}
.btn-bagde-primary{border: 0px;  padding: 10px !important;  border-radius: 20px;background: #0f1451;color: white; font-size: 13px;}

.progress, .progress-stacked{height: 10px; border-radius: 0px;background: #eafbf9;}
.progress-bar{background-color: #0f1451; background-image: linear-gradient(to right, #90CAF9, #3F338B  );}
.border-none{ border: none;}
.alertMsgSmall {width: 66%;left: 14%;text-align: center; font-size: 10px;  padding: 9px;line-height: 16px;margin-bottom: 0px;}
.alertMag > .alertMsgSmall {width: 100%;left: 0%;text-align: center; font-size: 10px;  padding: 9px;line-height: 16px;margin-bottom: 0px;}
.custom_badge {
  font-size: 14px;
    padding: 4px 22px;
    /* margin-bottom: 4px; */
    /* text-transform: uppercase; */
    /* border-radius: 10px; */
    /* margin-left: -17px; */
    cursor: pointer;
    color: #8b8989;
}
.krobker-ads-main{
  padding: 50px 100px;
}
.viewTciket{
  background: #1976d221;
    padding: 10px 3px;
    margin: 7px 0px;
    border-radius: 10px;
}
.viewTciket,  .left-first .right-first{ 
  font-size: 14px;
}
.viewTicketTitle p {
  margin-bottom: 0px;
}
.viewTicketTitle p:first-child{
  font-size: 16px;
}

.viewTicketTitle p:last-child{
  font-size: 13px;
  color: grey;
}

.ticketnumber {
  color: #000f26c2;
}

.revItemTK {
  margin-right: 10px;
  width: 16%;
  background: #00133d;
  border-radius: 33px;
  padding: 31px 20px;
  color: white;
}

.revItemTKSer {
  margin-right: 10px;
  width: 30%;
  background: #1976D21F;
  border-radius: 33px;
  padding: 31px 20px;
  color: grey;
  box-shadow: 0px 0px 4px 1px #80808052;
}
.w-20{
  width: 20%;
}
.view-listing-dashboard{
  height: 100px;
  position: relative;
}
.view-listing-dashboard > a{
  bottom: 0px;
    position: absolute;
    right: 0px;
}
.notfoundCss{
  height: 304px;
  margin: -20px 0px -20px -8px !important;
    padding: 100px 10px;
    background: #1976D21F;
}
.notfoundCss > p {
  font-size: 26px;
    margin-bottom: 34px !important;
}
.btn-primary-dash{
  width: fit-content;
    background: #002576;
    color: white;
    border-radius: 4px;
}
.revCSer > h3{
  font-size: 22px;
}

.revCSer > p:nth-child(2){
  font-size: 22px;
}
.revCSer > p:nth-child(4){
  text-align: right;
    color: rgb(0, 19, 61);
}

.revItemTK:nth-child(2){
  background: #002576;
}
.revItemTK:last-child{
  background: #2E7D6F;
}

.krobker-ads-main-content{
  background: #1976D20A;
    padding: 21px;
    border-radius: 10px;
    border: 2px solid #0049b612;
}
.krobker-ads-main-content > svg{
  font-size: 2rem;
  color: #57cf57;
  margin-right: 11px;
}
.krobker-ads-main-content > div > h5{
  font-size: 1.5rem;
}

.widgetOne {
  margin: 20px 0px;
  /* padding-bottom: 10px; */
}
.aboutDesc h5 , .widgetOne > div > h5{
  color: #3F338B;
}
.aboutDesc p{
  color: #000000;
}
.btn:hover{
  opacity: 0.7;
    color: black;
}
.widgetOne > div > p{
  color: grey;
}

.jt-space-between{
  justify-content: space-between;
}


.badge-grey{
  color: #252525;
    background: #e8e8e8;
}
.badge-green{
  color: #fff;
  background: #005c3c;
}
.span_text
{
 text-align: left;
 font-size: 11px;
}
.badge-red{
  color: #fff;
  background: red;
}
.timeEstimate{border-top: 1px solid #b9b9b9;
  padding-top: 24px;
  color: #faf8f8;}

  /* a.login_user_pic {display: inline-block;text-decoration: none; color: white !important;} */

a {display: inline-block;text-decoration: none; color: #0f1451}

h1 {font-size: 34px;}
h1, h2, h3, h4, h5, h6 {font-weight: 100;color: #2e2e2d;}
section {padding: 60px 0px;}
ul, ol {margin: 0;padding: 0;list-style-type: none;}
p:last-of-type {margin-bottom: 0;}
.container {max-width: 1200px;}
h3 {font-size: 26px;font-weight: 500;}
h2 {font-size: 38px;line-height: normal;}
h6 {font-size: 16px;font-weight: bold;line-height: 22px;}
.color_primery {color: #3565cd;}
.p_subus {color: #000000;}
/* buttons*/
.btn-outline-primary {text-transform: uppercase;margin-right: 8px;border: 2px solid #000000;font-size: 15px;font-weight: 500;border-radius: 0;padding: 12px 27px;transform: skewX(-12deg);display: inline-block;position: relative;font-weight: 500;color: #000000;border-style: solid;border-width: 2px 2px 2px 2px;border-color: #000000;border-radius: 0px 0px 0px 0px;padding: 12px 27px;transform: skewX(-12deg);display: inline-block;position: relative;}
.btn-outline-primary span {transform: skewX(12deg);display: inline-block;}
.btn-outline-primary:hover {background-color: #3565cd;font-weight: 500;border-radius: 0;padding: 12px 27px;transform: skewX(-12deg);border-color: #9c0;}
.btn-outline-orange {border: 2px solid #3565cd;color: #ffffff;background-color: #3565cd;font-weight: 500;border-radius: 0;padding: 12px 27px;transform: skewX(-12deg);}
.btn-outline-primary span {transform: skewX(12deg);display: inline-block;}
.btn-outline-primary:hover {color: #fff;background-color: #3565cd;border-color: #3565cd;}
.btn-outline-primary:before {content: "";background: #fff;position: absolute;display: block;transition: all 0.5s;width: 0;height: 100%;top: 0;left: 0;}
.btn-outline-primary:hover:before {background-color: #3565cd;width: 100%;}
.btn-outline-orange:hover {color: #000;background-color: #ffffff00;border-color: #000000;}
.btn-outline-orange:before {content: "";background: #fff;position: absolute;display: block;transition: all 0.5s;width: 0;height: 100%;top: 0;left: 0;}
.btn-outline-orange:hover:before {background-color: #fff;width: 100%;}
.form-check{ padding-left: 0px;}
/* top-banner-sec */
.top-banner {background: url(../public/img/header_t-bg.png) no-repeat;background-size: 100% 100%;background-color: #dfeaff;padding-top: 77px;}
.top-container {max-width: 1400px;}
.navbar-custom .nav-link {color: #ffffff;font-weight: 500;padding: 25px 0px;text-transform: uppercase;}
.navbar-custom .nav-item {margin-right: 0px;font-size: 20px;}
.navbar-custom .navbar-nav .nav-link.active,
.navbar-custom .navbar-nav .nav-link.show {color: #ffffff  ;}
.sub_title {background-color: #fff;padding: 6px 28px;font-size: 20px;border-radius: 30px;color: #104cba;font-weight: 500;}
.main-heading {color: #000000;font-size: 50px;line-height: 60px;}
.p_one {max-width: 547px;color: #000000;font-size: 24px;line-height: 30px;font-weight: 500;margin: 25px 0 34px;}
.p_subus {max-width: 566px;font-weight: 500;}
.bottom_head {padding: 100px 0 150px;}
.heading_top p {max-width: 750px;margin: auto;font-size: 18px;line-height: 28px;}
.steps li {margin-right: 60px;}
.steps li:last-child {margin-right: 0;}
.box_icon {background-color: #ffffff;border-radius: 50%;width: 150px;height: 150px;display: flex;align-items: center;justify-content: center;box-shadow: 0 0 50px #e9e9e9;margin: auto;}
.process_content p {font-size: 14px;line-height: 20px;text-align: center;margin-top: 20px;}
.steps_line {position: absolute;top: 30px;left: 40px;right: 0;z-index: -1;}
.steps {margin-top: 80px;padding-bottom: 90px;}
.p1 {position: relative;top: -32px;}
.p2 {position: relative;top: 90px;}
.p3 {position: relative;top: -36px;}
.p4 {position: relative;top: 67px;}
.decription_sec {background: url(../public/img/blue-bg.jpg) no-repeat center center;background-size: cover;}
.des_points::before {content: "";width: 2px;height: 143px;background-color: #ffffff;position: absolute;top: 9px;left: 11px;}
.des_points li {color: #fff;position: relative;padding-left: 40px;margin-bottom: 20px;}
.des_points li::before {content: "";background: url(../public/img/check-icon.png) no-repeat;position: absolute;left: 0;top: 1px;width: 24px;height: 24px;}
.des_points li:last-child {margin-bottom: 0;}
.circle_icon_1 {background: url(../public/img/s-icon-1.png) no-repeat;width: 90px;height: 98px;animation-duration: 30s;animation-iteration-count: infinite;animation-name: rotateme;animation-timing-function: linear;}
.rotateme_2 {animation-name: rotatemetwo;}
.ser_part {border-left: 1px solid #e3e3e3;position: relative;}
.ser_part:nth-child(1),
.ser_part:nth-child(4),
.ser_part:nth-child(7) {border-left-width: 0;}
.header{position: absolute;top: 0px;left: 0;right: 0;}

@keyframes rotateme {0% {transform: rotate(0deg)}
to {transform: rotate(1turn)}}
@keyframes rotatemetwo {0% {transform: rotate(0deg)}
to {transform: rotate(-1turn)}}
.max_with {max-width: 100% !important;}
.rating_star i {color: #555555;}
.checked_star {color: #fec42d !important;}

.our_clients {background: url(../public/img/client-bg.jpg) no-repeat top center;}
.client_box {box-shadow: 0 0 8px #e9e9e9;border-radius: 0;}
.client_box .card-body {padding: 24px 20px;}
.client_img_box {width: 100px;height: 100px;border: 2px solid #3464cd;margin: -71px auto;padding: 6px;background-color: #fff;}
.client_box p {font-size: 14px;line-height: 24px;color: #555555;}
.client_box h4 {font-size: 18px;color: #000000;}
.client_box h5 {font-size: 14px;color: #555555;font-weight: 500;}
.clients-slider .clients-li {padding: 90px 10px 0px 10px;}
.client_box::before {content: "";background-color: #3464cd;width: 100%;height: 3px;position: absolute;bottom: 0;}
.clients-slider .slick-slide {margin: 0 0px;}
/* .custom_mb {margin-bottom: -270px;} */
.clients-slider .slick-arrow {border: 0;background: transparent;}
.clients-slider .slick-prev {position: absolute;right: 40px;top: -110px;left: auto;background:url(../public/img/left_slick_icon.png) no-repeat center center/cover;width:30px;height:30px;}
.clients-slider .slick-next {position: absolute;right: 0;top: -110px;background:url(../public/img/right_slick_icon.png) no-repeat center center/cover;width:30px;height:30px;}
.slick-prev:before, .slick-next:before{opacity: 1;color: transparent;font-size: 0px;}

.footer_free_consult {background: #3464cd;padding: 25px 0 20px;}
.footer_free_consult h3 {font-size: 30px;font-weight: 600;}
.btn-fill {border: 2px solid #0a2d7a;background-color: #0a2d7a;}
.btn-fill:hover {border-color: #ffffff;}
.minHeight{ min-height: 600px;}
.roundImage{    border-radius: 50%;height: 110px; width: 112px;}
.imageOver{  display: none;  width: 110px; height: 110px;border-radius: 50%;/* border: 1px solid black; */
  text-align: center;line-height: 94px;background: #0b0b0b;  color: white;margin-top: -118px;z-index: 3;position: relative;
  opacity: 0.5;}

.roundImage:hover .imageOver { display: block;}​

/* footer */
.footer-outline {background-color: #0F1451; color: #ffffff;}
.footer {margin: 0px 0 0 0;background: #0f1451;    font-weight: 100;}
.luxurycarmarket {position: relative;z-index: 1;}
.luxurycarmarket .footer-logo-img {position: absolute;top: -62px;left: 0px;z-index: -1;}
.luxurycarmarket .border-down {border-bottom: 1px solid #cccccc;padding-bottom: 23px;}
.social_connect {margin: 30px 0;}
.social_connect a {font-size: 18px;background: #3464cd;color: rgb(255, 255, 255);padding: 11px 12px;width: 46px;height: 46px;border-radius: 6px;margin-right: 8px;text-align: center;}
.social_connect a:hover {background: #d3e0ff;color: #3464cd;}
.footer_links h4 {font-size: 26px;color: #ffffff;margin-bottom: 0px;}
.footer_links ul {list-style: none;position: relative;padding-top: 10px;}
.footer_links ul li {padding-top: 12px;font-size: 16px;color: #fff;font-weight: 400;}
.footer_links ul li a {padding-left: 0px;display: flex;align-items: center;color: #fffdfd;font-size: 16px;font-weight: 500;}
.footer_links ul li a:hover {color: #3464ca;}
.footer_links ul li a i {padding-right: 15px;}
.rights {background: #0F1451;}
.rights p,
.rights a {color: #ffffff;font-size: 15px;margin-bottom: 0px;padding: 10px 0; text-align: center;}
.rights a:hover {color: #3464cd;}
.footer_links.get_in_touch ul li a i {font-size: 16px;color: #ffffff;}
.footer_links.get_in_touch ul li a label {    color: #b6b6b6;}
.footer_links.get_in_touch ul li a label span {display: block;font-weight: 500;}
.luxurycarmarket p {max-width: 370px;}

.navbar .navbar-brand img.on-scroll {display: none;}
header .navbar {background:  #00133D; padding: 0;-ms-transition: all 0.3s ease-out;-moz-transition: all 0.3s ease-out;-webkit-transition: all 0.3s ease-out;-o-transition: all 0.3s ease-out;transition: all 0.3s ease-out;/* height: 64px; */}
header.sticky-header .navbar {top: 0;position: fixed;left: 0;right: 0;width: 100%;background: #fff;z-index: 999;box-shadow: 0px 1px 8px 2px rgb(0 0 0 / 20%);}
header.sticky-header .navbar .navbar-brand img {max-width: 60%;padding: 8px 0;}
header.sticky-header .navbar-custom .nav-link {color:#fcfcfc  !important;font-weight: 400;}
header.sticky-header .navbar-custom .nav-link.active {color: #0a2d7a !important; }

/* ragister-page-css */
.dashboard-top {background-color: #ffffff;box-shadow: 3px 3px 7px 0px #ccc;background: none;}
.reg_h2 {font-size: 26px;color: #000;font-weight: 500;}
.b_line::before {content: "";width: 100%;height: 1px;background-color: #dfdfdf;position: absolute;left: 0;bottom: 0;}
.ca_h3 {font-size: 20px;color: #333333;}
.se_padg {padding: 125px 0;}
.btn-min-wth {min-width: 312px;}
.bord_sep {border-right: 1px solid #dfdfdf;}
.reg_instruction li {position: relative;padding-left: 36px;font-size: 16px;color: #555555;line-height: 24px;margin-bottom: 14px;}
.reg_instruction li::before {content: "\eed9";font-family: IcoFont;position: absolute;left: 0;top: 1px;color: #3565cd;font-size: 24px;}
.min_card {max-width: 441px;min-height: 518px;border-radius: 15px;}
.min_card .card-header {font-size: 20px;color: #000000;background-color: #ffffff;padding: 16px 30px;font-weight: 600;border-radius: 15px 15px 0 0;}
.min_card .card-body {padding: 46px 40px;}
.card_p {color: #555555;line-height: 24px;}
.check_label {font-size: 14px;color: #717070;}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {transition: background-color 9999s ease-in-out 0s;}

/* dashboard pages */
.container.container-dashboard {max-width: 1450px;}
.dashboard {background-color: #fff;min-height: 850px;margin-bottom: 50px;border-radius: 20px;box-shadow: 0 0.5rem 3rem rgba(0, 113, 220, 0.15);display: table;width: 100%;overflow: hidden;}
.dashboard-menu {min-width: 270px;background-color: #0071dc;display: table-cell;vertical-align: top;width: 270px;position: sticky;top: 20px;}
.dashboard-content {padding-left: 30px;padding-right: 30px;vertical-align: top;padding-bottom: 30px;}
.school-logo {width: 100px;height: 100px;background-color: #fff;border-radius: 50%;text-align: center;margin: 0px auto;border: 2px solid #fff;position: relative;overflow: hidden;}
.school-logo img {width: 100px;height: 100px;object-fit: cover;}
.school-title h3 {font-size: 18px;text-align: center;margin-top: 25px;color: #fff;margin-bottom: 0;font-weight: 700;}
.school-title {padding: 30px;}
.school-title p {font-size: 14px;color: #fff;text-align: center;}
.school-title a.edit {position: absolute;top: 0;width: 25px;height: 25px;background: #fff;z-index: 9999;left: 60%;border-radius: 50%;font-size: 14px;text-align: center;line-height: 25px;}
.user-menu {padding-left: 20px;margin-top: 20px;}
.user-menu a {font-weight: 600;color: #fff;letter-spacing: 0.5px;display: flex;align-items: center;width: 100%;padding: 20px 26px;}
.user-menu a span {display: inline-block;width: 35px;}
.user-menu a svg {fill: #fff;}
.user-menu a.active {background: #fff;border-radius: 40px 0px 0px 40px;color: #000;}
.user-menu a.active svg {fill: #000;}
.left-heading h4 {font-size: 24px;margin-bottom: 0;font-weight: 600;}
.left-heading p {color: #555;font-size: 14px;}
.search-dashboard {display: inline-block;height: 38px;width: 38px;background: #f5f5f5;text-align: center;line-height: 38px;border-radius: 6px;}
.right-search .btn.yellow_btn {height: 38px;line-height: 38px;font-weight: 400;margin-left: 15px;}
.dashboard-heading {margin: 30px 0px;}
.table-dashboard .table thead tr th {border: transparent;font-size: 14px;font-weight: 600;padding: 11px 15px;color: #000;}
.table-dashboard .table thead tr {background-color: #f5f5f5;}
.table-dashboard .table thead tr th:first-child {border-radius: 6px 0px 0px 6px;}
.table-dashboard .table thead tr th:last-child {border-radius: 0px 6px 6px 0px;}
.table-dashboard .table tbody tr {box-shadow: 0 0 0 1px #ddd;border-radius: 6px;}
.table-dashboard .table tbody tr.separator {height: 15px;box-shadow: none;}
.table-dashboard .table tbody tr td {border: transparent;font-size: 14px;padding: 15px 15px;}
.text-danger {color: #ee3636 !important;}
.text-success {color: #0bab5a !important;}
.assigment-details {padding: 30px;}
.assigment-data h4 {font-weight: 700;color: #001c34;margin-bottom: 20px;}
.assigment-data h4 span {font-size: 14px;font-weight: 600;color: #555;border-left: 1px solid #ddd;padding-left: 10px;}
.assigment-data ul li {font-size: 14px;color: #555;margin-top: 10px;}
.assigment-data ul li i {font-size: 18px;color: #0071dc;margin-right: 5px;}
.btn-right a {font-size: 14px;background: #f5f5f5;height: 39px;line-height: 39px;padding: 0px 20px;border-radius: 4px;font-weight: 600;}
.btn-right a i {font-size: 18px;}
.btn-right a.view-details {background-color: #ffc221;}
.btn-right a.pay {background-color: #0071dc;color: #fff;}
.price p:first-child {font-weight: 600;}
.price p span {color: #0071dc;}
.price p {margin-bottom: 0;margin-top: 20px;}
.price p i {width: 13px;height: 13px;background-color: #0071dc;display: inline-block;margin-right: 5px;}
.price p i.yellow {background-color: #ffc221;}
.form-details .right_form_inner {padding: 0;background: transparent;}
.top-heading.dashboard-heading-main h5 {font-size: 24px;}
.top-heading.dashboard-heading-main {margin-bottom: 30px;}
.alert-msg {font-size: 14px;color: #333;margin-top: 10px !important;}
.form-details .right_form_inner .upload-wrapper .upload-btn-wrapper button {background-color: transparent;padding: 0;}
.dashboard-slider .carousel button {opacity: 1;width: 6%;}
.dashboard-slider .carousel button span {background-image: none;background-color: #fff;width: 38px;height: 38px;border-radius: 4px;}
.dashboard-slider .carousel button span i {color: #000;font-size: 22px;line-height: 38px;}
.dashboard-slider .carousel button span:hover {background-color: #ffc221;}
.slider-content h5 {color: #fff;font-weight: 600;}
.slider-content h5 span {color: #ffc221;}
.slider-content ul {display: flex;justify-content: center;}
.slider-content ul li {font-size: 14px;padding: 0px 25px;}
.slider-content {bottom: 8.25rem;}
.middle-icon {background-color: #ffc221;width: 64px;height: 64px;text-align: center;line-height: 62px;border-radius: 50%;margin: 0 auto;}
.total-items h2 {font-size: 36px;font-weight: 700;margin-top: 30px;}
.total-items p {text-transform: uppercase;font-size: 14px;font-weight: 600;color: #555;}
.total-items a {width: 100px;height: 33px;border: 2px solid #000;border-radius: 4px;line-height: 31px;font-size: 14px;font-weight: 600;margin-top: 30px;}
.total-items.refferal .middle-icon {background-color: #3bc47e;}
.total-items.wallet .middle-icon {background-color: #2686ff;}
.yellow-band {background: #ffc221;display: flex;align-items: center;justify-content: space-between;padding: 15px;}
.yellow-band h6 {margin-bottom: 0;display: flex;align-items: center;}
.yellow-band h6 span {font-size: 30px;margin-left: 15px;}
.yellow-band a {text-decoration: underline;font-weight: 700;color: #000;}
.yellow-content {padding: 30px;}
.wallet-blance .yellow-content h5 {color: #fff;font-weight: 700;}
.wallet-blance .yellow-content p {font-size: 18px;font-weight: 600;color: #fff;}
.bulk-offer {border: 1px solid #ddd;border-radius: 10px;overflow: hidden;margin-bottom: 20px;}
.bulk-offer .bulk-offer-content .project_icon {background-color: #ffc221;position: absolute;left: -64px;top: 38px;}
.project_icon p {font-size: 18px;color: #222;transform: rotate(-45deg);text-align: center;font-weight: 600;}
.bulk-offer-content {position: relative;}
.bulk-img img {width: 100%;}
.bulk-content h6 {font-weight: 600;margin-bottom: 6px;}
.bulk-content p {font-size: 14px;line-height: 20px;color: #555555;}
.bulk-content {padding: 20px 30px 20px 60px;}
.bulk-content ul {display: flex;}
.bulk-content ul li {font-size: 14px;font-weight: 600;color: #000;margin-top: 8px;position: relative;padding-left: 20px;margin-right: 15px;}
.bulk-content ul li:before {content: "";position: absolute;width: 10px;height: 10px;border-radius: 50%;background-color: #0071dc;top: 5px;left: 0;}
.bulk-offer:nth-child(even) {background-color: #f4faff;}
.font-600 {font-weight: 600;}
.estimate {padding: 10px 20px;background-color: #bbccda;}
.estimate .top-heading h5 {font-size: 18px;}
.estimate .top-heading .filter_subject select {height: 39px;padding-left: 15px;width: 105px;background-color: #0071dc;border: 1px solid #0071dc;color: #fff;}
.estimate .top-heading .filter_subject i {top: 12px;color: #fff;}
.range_slider {padding-top: 15px;}
.range_input {background: transparent;font-size: 18px;color: #000;border: 0;line-height: 40px;text-align: center;width: 100%;font-weight: 600;}
.range_slider .ui-slider-horizontal {height: 1px;border: 2px solid #000;}
.range_slider .ui-slider .ui-slider-handle {width: 28px;height: 28px;border-radius: 50%;background: #ffc221;outline: 0;border: 1px solid #0071dc;top: -14px;}
.label {margin-bottom: 10px;}
.slider-range {background-color: #f4faff;padding: 30px 30px 40px;}
.earning h4 {font-size: 24px;font-weight: 700;color: #0071dc;}
.earning h4 sup,
.earning h4 sub {font-size: 16px;font-weight: 400;color: #000;}
.earning p {text-transform: uppercase;margin-bottom: 6px;}
.coustom {background: #0071dc;padding: 20px;}
.refreal {color: #fff;font-weight: 600;}
.refreal img {margin-right: 15px;vertical-align: bottom;}
.refreal a {margin-left: 15px;}
.share a {height: 39px;background: #ffc221;line-height: 39px;padding: 0px 20px;border-radius: 4px;border: 1px solid transparent;margin-left: 8px;font-size: 14px;}
.share a:last-child {background: transparent;color: #fff;border: 1px solid #fff;}
.referral-content h1 {font-size: 40px;font-weight: 700;color: #ffc221;}
.referral-content h1 sup {font-size: 24px;color: #000;}
.yellow-bg {background: #ffc221;padding: 10px 30px;transform: skew(-30deg, 0deg);}
.referral-content p {font-size: 18px;color: #000;margin-top: 20px;}
.yellow-bg p {color: #000;font-size: 18px;font-weight: 600;margin-top: 0px;transform: skew(30deg, 0deg);}
.referral-content {width: 50%;margin-left: 40%;padding: 50px 0px;}
.circul .icon {width: 120px;height: 120px;margin: 0 auto;background-color: #ffc221;min-width: 120px;}
.circul .icon span {display: block;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);font-size: 18px;color: #fff;}
.circul .icon:before {width: 84%;height: 84%;top: 9px;}
.circul {margin: 40px 0px;}
.circul p {font-size: 18px;font-weight: 700;color: #000;text-align: center;margin-top: 20px;}
.circul .icon.w-blance {background-color: #da2552;}
.circul .icon.c-blance {background-color: #3bc47e;}
.referral-book .nav .nav-item .nav-link {background-color: #bbccda;color: #000;font-weight: 600;border-radius: 6px 6px 0px 0px;margin-left: 15px;}
.referral-book .nav .nav-item .nav-link.active {background-color: #ffc221;}
.leader .top-heading p {max-width: 100%;font-size: 16px;margin-top: 0;}
.referral-book.leader .table tbody tr td {padding: 8px 15px;vertical-align: baseline;}
.benfits .delivered {padding: 0;}
.yellow-content h5 span {color: #ffc221;}
.money p {font-weight: 600;}
.money h5 {font-weight: 700;color: #0071dc;margin-bottom: 0;}
.right-form label {font-weight: 600;margin-right: 10px;}
.right-form input {height: 42px;width: 200px;margin-right: 10px;}
.right-form a.btn {height: 44px;line-height: 44px;}
.wallet-blance-inner {padding: 20px 0px;}
.add_money {padding: 40px 30px;}
.add_money p {font-size: 18px;color: #000;}
.add_money small {color: #000;}
.add_paise {height: 56px;background: #ffc221;border-radius: 4px;line-height: 54px;font-weight: 600;color: #000;border: 2px solid transparent;}
.add_paise.blue-paise {background: transparent;border: 2px solid #0071dc;color: #0071dc;}
.wallet-inner {padding: 30px;text-align: center;}
.wallet-inner h4 {font-size: 20px;font-weight: 700;}
.wallet-inner p {color: #000;}
.expert {padding-top: 50px;}
#user_nav {background: transparent;border: none;position: absolute;top: 20px;right: 30px;}

/* hover effect */
.btn-right a.view-details:hover {background: #da9e01;color: #fff;}
.btn-right a.pay:hover {background-color: #004e98;color: #fff;}
.left-btn a:hover {background-color: #da9e01;border-color: #da9e01;}
.right-btn a:first-child:hover {background-color: #004e98;color: #fff;}
.share a:hover {background-color: #da9e01;color: #fff;}
.share a:last-child:hover {background-color: #004e98;color: #fff;border-color: #004e98;}
.add_paise:hover {background-color: #ffe7a7;color: #000;}
.add_paise.blue-paise:hover {background-color: #d9edfd;color: #000;}
.table-responsive.table-dashboard {padding: 0px 2px;}
.table-dashboard .table tbody tr:hover {background-color: #f5f5f5;}
.user-menu a:hover {color: #000000;}
.user-menu a:hover svg {fill: #000000;}
.total-items a:hover {background: #0071dc;color: #fff;border: 2px solid #0071dc;}
.share a i {font-size: 18px;}
.yellow-band a:hover {color: #fff;}
.search-inner.essay-search .input-group span:hover {background-color: #004e98;color: #fff;}
.price_content a:hover {border: 2px solid #ffc221;background: #ffc221;}
.search {position: relative;display: flex;justify-content: flex-end;width: 280px;}
.search input {height: 39px;line-height: 38px;width: 100%;color: #000;padding: 0 10px;border: 0;background: #f5f5f5;border-radius: 0px;max-width: 38px;transition: all 0.3s ease;opacity: 0;cursor: pointer;}
.search a {color: #000;font-size: 18px;width: 20px;position: absolute;right: 0;top: 0px;text-align: center;pointer-events: none;background-color: #f5f5f5;height: 38px;min-width: 38px;border-radius: 4px;line-height: 38px;}
.search a .icofont-search-1 {font-size: 18px;}
.search a .icofont-close {display: none;font-size: 20px;}
.search input:focus {max-width: 100%;border-bottom: 1px solid #000;opacity: 1;cursor: text;}
.btn_contract {background-color: #3565cd;border-color: #3565cd;font-size: 14px;font-weight: 500;line-height: inherit;padding: 6px 30px;}
.btn_contract:hover {background-color: #ffffff;border-color: #000000;color: #000000;}

/* steps */
.step_barcon {display: block;background: #e5e7e6;padding: 15px;width: 102%;float: left;}
.step_barcon ul li {float: left;list-style: none;background: #fff;border: 1px solid #b3b3b3;color: #b3b3b3;padding: 5px;margin-right: 5px;border-radius: 5px;min-width: 209px;}
.step_num {float: left;width: 45px;height: 38px;text-align: center;background: #dfeaff;line-height: 38px;margin-right: 10px;font-size: 24px;font-weight: 700;border-radius: 5px;}
.step_info {float: left;text-transform: uppercase;text-align: left;}
.step_info label {font-size: 16px;font-weight: 700;margin: 0;line-height: 15px;margin-top: 5px;}
.step_info span {display: block;font-size: 12px;font-weight: 500;line-height: 12px;margin-top: 2px;}
.step_barcon ul li.active {background: #3565cd;border-color: #3565cd;}
.step_barcon ul li.active .step_num {background: #fff;color: #636564;}
.step_barcon ul li.active .step_info label {color: #fff;}
.step_barcon ul li.active .step_info span {color: #fff;}
.step_barcon ul {clear: both;overflow: hidden;float: left;}

.contract_boxes h4 {font-size: 20px;color: #000000;font-weight: 500;}
.contract_boxes {border-radius: 15px;padding: 50px 0;cursor: pointer;position: relative;}
.contract_boxes i {position: absolute;right: 10px;top: 10px;font-size: 36px;color: #3565cd;}
.dash_border {box-shadow: none !important;border: 2px dashed;border-color: #ccc;position: relative;}
.btn_next_width {min-width: 140px;}
.box-active {background-color: #e9f1ff;}
.box-active i {display: block !important;}
.login_user_pic img {
  border-radius: 50%;
  border: 2px solid #ffffff;
  /* width: 40px; */
  height: 30px;
  object-fit: cover;
  background: #ffffff;
}
.sm_text {font-size: 13px;}
.form-floating .form-control {border: 2px solid #dddddd;}

/* drag_drop_css */
.drag-area {border: 2px dashed #bfbfbf;height: 284px;width: auto;border-radius: 15px;display: flex;align-items: center;justify-content: center;flex-direction: column;background-color: #f3f6fd;}
.drag-area header {font-size: 15px;font-weight: 500;color: #4c5569;line-height: 22px;/* min-height: 43px; */}
.drag-area span {font-size: 25px;font-weight: 500;color: #fff;margin: 10px 0 15px 0;}
.drag-area button {font-weight: 600;padding: 8px 30px;}
.drag-area img {height: 100%;width: 100%;object-fit: cover;border-radius: 5px;}
.divider span {background-color: #f3f6fd;font-size: 14px;color: #95a3b4;position: relative;z-index: 2;text-transform: uppercase;padding: 0px 12px;}
.divider::before {content: "";width: 168px;height: 2px;background-color: #95a3b4;position: absolute;left: 0;right: 0;bottom: 10px;margin: auto;}

.doc_boxes {width: 126px;height: 141px;background-color: #f3f6fd;display: flex;align-items: center;justify-content: center;position: relative;}
.clos_icon {position: absolute;top: -8px;right: -8px;border: 1px solid #a4a4a4;width: 20px;height: 20px;font-size: 12px;border-radius: 50%;background-color: #ffffff;color: #000;display: flex;align-items: center;justify-content: center;}
.doc_img img {height: 141px;width: 126px;object-fit: cover;border-radius: 20px;}
.card-raised {/* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); */border: 0;}
.bg_colr_1 {background-color: #6200ea;}
.bg_colr_2 {background-color: #ffb300;}
.bg_colr_3 {background-color: #26a69a;}
.bg_colr_4 {background-color: #9c27b0;}
.card-raised::before {content: "";background-color: #6200ea;width: 3px;height: 100%;position: absolute;top: 0;border-radius: 0.25rem;}
.card-raised-1::before {background-color: #ffb300;}
.card-raised-2::before {background-color: #26a69a;}
.card-raised-3::before {background-color: #9c27b0;}
.icon-circle {height: 60px;width: 60px;display: flex;align-items: center;justify-content: center;border-radius: 50%;}
.display-5 {font-size: 40px;color: #212121;font-weight: 400;}
.t_cont {font-size: 18px;color: #555555;font-weight: 600;text-transform: uppercase;}
.table-dashboard thead tr {background-color: #f5f5f5;font-size: 14px;}
.table-dashboard thead tr th {border: transparent;font-size: 14px;font-weight: 600;padding: 11px 15px;color: #000;}
.table-dashboard tbody tr td {font-size: 14px;padding: 15px 15px;}
/* about */
.breadcum-sec {padding: 60px 0;background: url(../public/img/header_t-bg.png) no-repeat center bottom;background-color: #dfeaff;background-size: cover;}
/* model-login */
.modal-content {border-radius: 8px;overflow: hidden;}
@media (min-width: 576px) {}
.small_model {max-width: 420px;}
.modal-content_custom::before {content: "";width: 100%;height: 6px;position: absolute;bottom: 0;background-color: #3665cd;border-radius: 0 0 0.3rem 0.3rem;}
.modal-header_custom {background: url(../public/img/model-bg.png) no-repeat bottom center;position: relative;border-bottom: 0;padding-bottom: 26px;}
.modal-title_custom {font-size: 22px;font-weight: 600;color: #ffffff;}
.sm_text_m {font-weight: 400;color: #ffffff;}
.btn-close-custom {position: absolute;top: 22px;right: 22px;background: url(../public/img/close.png) no-repeat;opacity: inherit;padding: 0 !important;}
.forgot_password {color: #3665cd;font-size: 14px;margin: 10px 0px 20px;font-weight: 500;}
.register-now {text-align: center;font-size: 15px;font-weight: 500;color: #000;}
.inner_header {background-color: #ffffff;background: none;}
.user_top_dropdown {padding: 0;}
.user_top_dropdown li {border-bottom: 1px solid #ccc;}
.user_top_dropdown li:last-child {border-bottom: 0;}
.user_top_dropdown li a {font-size: 14px;font-weight: 500;padding: 7px 16px;}
.user_top_dropdown li a:focus,
.user_top_dropdown li a:hover {background-color: #3565cd;color: #fff;}

.contact-form {background: #fff;border-radius: 8px;margin-top: -93px;overflow: hidden;box-shadow: 0px 0px 13px 3px rgb(0 0 0 / 15%);}
.contact-form h3 {background: #f5f5f5;font-size: 36px;font-weight: 400;text-transform: capitalize;padding: 25px 0px;}
.contact-form-propsel {padding: 40px;}
.contact_content p {padding-top: 10px;font-weight: 500;color: #2c3c4a;margin-bottom: 30px;}
.phone_img {font-size: 24px;background-color: #3565cd;color: #ffffff;border-radius: 50%;width: 50px;height: 50px;display: flex;justify-content: center;padding: 20px;align-items: center;}
.phone_cont h3 {font-weight: 500;color: #040b11;margin: 0;font-size: 16px;text-transform: capitalize;}
.phone_cont a {font-size: 16px;color: #2c3c4a;font-weight: 400;}
.social li {list-style: none;display: inline-block;margin-right: 12px;}
.social li a {font-size: 24px;border: 1px solid #29235c;background-color: #ffffff;color: #29235c;width: 50px;height: 50px;border-radius: 50%;padding: 20px;display: flex;justify-content: center;align-items: center;}
.contact_content h4 {font-size: 24px;margin-top: 40px;}
.contact_content .d-flex {margin-bottom: 30px;}
.contact-form-propsel .form-floating input:focus,
.contact-form-propsel .form-floating select:focus,
.contact-form-propsel .form-floating textarea:focus {box-shadow: none;}
.accordion-body{padding: 10px 0px;}
.accordion-button:focus {
  box-shadow: none;
}
/* faq */
.faq h2 {font-size: 22px;color: #000000;font-weight: bold;}
.accordion-button {background-color: #ffffff;padding: 9px 10px;font-size: 16px;color: #00133d;}
.accordion-item {margin-bottom: 15px;border: 0;border-radius: 5px !important;}
.accordion-button:not(.collapsed) {padding: 5px 0px; color: #00133d;background-color: #ffffff;box-shadow: none;font-weight: 100;}

/*pricing*/
.pricing-table {background-color: #fff;border-radius: 8px;padding: 40px 60px;margin-top: 40px;}
.pricing-table h4 {font-size: 24px;text-transform: capitalize;margin: 26px 0px;font-weight: 600;min-height: 57px;}
.pricing-table p {font-size: 18px;color: #555;line-height: 28px;margin-bottom: 30px;}
.most-popular {position: absolute;height: 151px;width: 152px;top: -10px;left: -10px;display: block;background-size: cover;}
.pricing-area {position: relative;z-index: 1;}
.bg-f9f9f9 {background-color: #f9f9f9;}
.pb-70 {padding-bottom: 70px;}
.pt-100,
.ptb-100 {padding-top: 100px;}
.single-pricing-box {background-color: #fff;border-radius: 5px;margin-bottom: 30px;padding-bottom: 40px;padding-top: 40px;position: relative;text-align: center;z-index: 1;}
.single-pricing-box .pricing-header .icon {background-color: #ffe9e3;border-radius: 50%;color: #fe4c1c;font-size: 50px;height: 90px;line-height: 100px;margin-bottom: 30px;margin-left: auto;margin-right: auto;position: relative;text-align: center;transition: var(--transition);width: 90px;}
.single-pricing-box .pricing-header .icon .circles-box {animation-duration: 30s;animation-iteration-count: infinite;animation-name: rotateme;animation-timing-function: linear;border-radius: 50%;bottom: 0;left: 0;position: absolute;right: 0;top: 0;}
.single-pricing-box .pricing-header h3 {background-color: #f9f9f9;font-size: 24px;margin-bottom: 0;padding: 15px 10px 12px;color: #000;font-weight: 700;}
.single-pricing-box .pricing-features {margin-bottom: 35px;padding-left: 10px;padding-right: 10px;padding-top: 30px;}
.single-pricing-box .pricing-features ul {list-style-type: none;margin-bottom: 0;padding-left: 0;}
.single-pricing-box .pricing-features ul li {color: #4b5280;font-size: 16px;font-weight: 500;margin-bottom: 13px;}
.single-pricing-box .price {font-size: 40px;font-weight: 800;color: #000;}
.single-pricing-box .price span {color: #4b5280;display: block;font-size: 18px;font-weight: 600;margin-top: 16px;}
.single-pricing-box .default-btn {margin-top: 30px;}
.col-lg-4:nth-child(2) .single-pricing-box .pricing-header .icon {background-color: #e5e0f2;color: #290390;}
.col-lg-4:nth-child(3) .single-pricing-box .pricing-header .icon {background-color: #e4f4f3;color: #1fa299;}
.single-pricing-box .pricing-header .icon .circles-box .circle-one {background-color: #fe4c1c;border-radius: 50%;bottom: 8px;height: 12px;left: 8px;position: absolute;width: 12px;z-index: 1;}
.col-lg-4:nth-child(2).single-pricing-box.pricing-header.icon.circles-box.circle-one {background-color: #290390;}
.col-lg-4:nth-child(3).single-pricing-box.pricing-header.icon.circles-box.circle-one {background-color: #1fa299;}
@keyframes rotateme {0% {transform: rotate(0deg)}100% {transform: rotate(1turn)}}
.heading p {max-width: 700px;margin: 0 auto;}
.vission .mission-icon {flex: 0 0 58px;margin-right: 20px;}

.appointment figure{margin-bottom: 30px;}
.appointment h3{font-size: 24px;font-weight: 500;margin-bottom: 20px;}
.appointment p {margin-bottom: 25px;}
.appointment{padding: 178px 0;}

.party_select .btn-secondary {color: #000;background-color: transparent;border: 2px solid #ccc;text-align: left;height: 58px;position: relative;}
.party_select .btn-secondary:active{background-color: transparent;border: 2px solid #ccc;color: #000000;}
.party_select .dropdown-toggle::after {position: absolute;right: 11px;top: 16px;content: "\eab2";font-family: IcoFont;border: 0;color: #555555;}
.party_select .dropdown-item{font-size: 14px;color: #000000;font-weight: 500;padding: 12px 12px;}
.party_select .d_name{color: #555555;line-height: 14px;}
.party_select .dropdown ul li{border-bottom: 1px solid #dddddd;}
.party_select .dropdown ul li:last-child{border-bottom: 0;}
.party_select .dropdown-item:active{background-color: transparent;}

.user_detail p{font-size: 14px;color: #555555;}
.add_deatils{border: 1px solid #eeeeee;border-radius: 10px;background-color: #f9fcfe;padding: 15px;display: flex;justify-content: space-between;align-items: center;}
.p_circle{background-color: #3565cd;color: #ffffff;font-weight: 600;width: 50px;height: 50px;border-radius: 50%;display: flex;align-items: center;justify-content: center;font-size: 24px;}

/* Canvas_css */
.wrapper {position: relative;width: 724px;height: 230px;-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;border: 2px solid #dddddd;border-radius: 15px;background-color: #fff;}
.signature-pad {position: absolute;left: 0;top: 0;width: 724px;height: 230px;}


/*Responsive*/

@media (max-width: 1700px){
    .main-heading{font-size: 48px;}
   }
   
   @media (max-width: 1700px){
    .main-heading{font-size: 46px;}
   }
   
   @media (max-width: 1300px){
    .navbar-brand svg{max-width: 250px;}
    .navbar-custom .nav-link{font-size: 14px;}
    .make-call .number{font-size: 18px;}
    .btn-outline-primary{font-size: 14px;}
    .top_content{margin-top: -50px;}
    h6{font-size: 15px;}
    .main-heading{font-size: 43px;}
    .style_head_one .top_content .main-heading{font-size: 40px;line-height: 54px;}
    .style_head_one .top_content .punch_text{font-size: 20px;line-height: 22px;margin-bottom: 0;}
    .style_head_one .top_content .main-heading{margin-top: 0;}
    .style_head_one .top_content{margin-top: 0;}
    .style_head_one .top_content .breadcrumb{margin-bottom: 0;}
    .devops_consulting .main-heading{font-size: 44px;}
   }
   
   @media (min-width: 1200px){
    #user_nav{display: none;}
    .t_cont{font-size: 14px;}
    .h6_title, .activity_card p {font-size: 13px;}
   }

  
   
   @media (max-width: 1200px){
    /* dashboard */
    .dashboard-menu{display: block;min-width: 100%;top: 0px;}
    .user-menu{display: none;}
    .dashboard{display: block;}
    .school-title{display: flex;align-items: center;padding: 8px 30px;}
    .school-title h3{margin-top: 0px;text-align: left;}
    .school-logo{width: 50px;height: 50px;}
    .school-title a.edit{top: -7px;width: 20px;height: 20px;line-height: 20px;font-size: 11px;}
    .school-title .position-relative{margin-right: 15px;}
    .user-menu a.active{border-radius: 0px 0px 0px 0px;}
    .user-menu a{padding: 10px 26px;}
    .user-menu{padding-left: 20px;margin-top: 10px;padding-bottom: 10px;padding-right: 20px;}
    .user-menu.active{display: block;}
    .slider-content ul li{padding: 0px 15px;}
    .refreal{flex: 0 0 60%;}
    .share{flex: 0 0 40%;}
   }
   
   @media (max-width: 1199px){
    .navbar-toggler{padding: 0;border: 0;}
    .navbar-toggler:focus{box-shadow: none;}
    .navbar-custom .nav-link{color: #000000;border-bottom: 1px solid #e5e5e59e;padding: 12px 0;}
    .mobile_nav{background-color: #ffffff;padding: 10px 20px;z-index: 9;}
    .call_mob{min-width: 180px;}
    .btn_mob-top{border: 2px solid #ff5f04;color: #ff5f04;}
    .btn_mob_with{width: 100%;}
    .navbar-custom .nav-item{margin-right: 0;}
    .main-heading{font-size: 37px;line-height: 50px;}
    .navbar-custom .navbar-nav .nav-link.active,
    .navbar-custom .navbar-nav .nav-link.show{color: #3565cd;}
    .dropdown-menu h5{position: relative;padding-bottom: 10px;font-size: 18px;}
    .navbar-custom .dropdown-menu{position: static;}
    .make-call .number{color: #000000;}
    .call_details{margin-top: 15px;}
    .footer_btns .btn-outline-primary{min-width: 230px;}
    .gx_custom{--bs-gutter-x: 1rem;}
    .devops_consulting .main-heading{font-size: 38px;}
    .devops_consulting .punch_text{font-size: 18px;line-height: 24px;margin: 22px 0 !important;}
    .btn_contacts{margin-left: 0 !important;margin-top: 15px;}
    .bottom_head{padding: 80px 0 60px;}
    .p_one{margin: 15px 0 24px;}
    .g-5{--bs-gutter-x: 1.5rem;}
    .step_num{width: 38px;height: 38px;}
    .step_barcon ul li{min-width: 178px;}
   }
   
   @media (max-width: 991px){
    .main-heading{font-size: 26px;line-height: 34px;}
    .sub_title{font-size: 14px;}
    .top-banner{min-height: auto;}
    h2{font-size: 24px;}
    .progressbar h5{font-size: 16px;}
    .footer_free_consult h5{font-size: 27px;letter-spacing: 0;}
    .top_content{margin-top: 0px;}
    .style_head_one .devops_head{padding-bottom: 50px !important;}
    .style_head_one .top_content .main-heading{font-size: 32px;line-height: 45px;margin-top: 16px !important;}
    .style_head_one .top_content .punch_text{font-size: 16px;margin-bottom: 0 !important;}
    .menu_image{display: none;}
    .devops_consulting .main-heading{font-size: 28px;margin-top: 0 !important;}
    .devops_consulting .bottom_head{padding-bottom: 56px !important;}
    .nj-service__picture{width: 80px;margin-right: 10px;}
    .nj-services-other__inside{padding: 30px;}
    .botton-beadcum .breadcrumb{font-size: 13px;}
    .bottom_head{padding: 40px 0 40px;}
    .p_one{max-width: 100%;font-size: 18px;line-height: 24px;margin: 12px 0 21px;}
    .navbar-brand img{max-width: 60%;}
    .box_icon{width: 100px;height: 100px;}
    .box_icon img{max-width: 36%;}
    .steps li{margin-right: 20px;}
    .steps_line{top: 30px;left: 24px;max-width: 94%;}
    .p1{top: -12px;}
    .p2{top: 60px;}
    .p3{top: -14px;}
    .steps{margin-top: 30px;padding-bottom: 67px;}
    .heading_top p{max-width: 650px;font-size: 16px;line-height: 24px;}
    .icons_lists p{font-size: 14px;line-height: 20px;}
    .client_box h4{font-size: 16px;}
    .log_btns a{display: block;}
    .se_padg{padding: 45px 0;}
    .step_num{display: none;}
    .step_barcon ul li{min-width: 130px;}
    .step_barcon{padding: 6px;}
    .wrapper{width: auto;}
    .signature-pad{width: 100%;}
    .breadcum-sec{padding: 25px 0;}
    .pricing-area{padding-top: 60px;}
    .faq h4{font-size: 18px;}
    .faq .accordion-button{background-color: #f7f7f7;padding: 10px;font-size: 14px;line-height: 18px;}
    .faq .accordion-body{padding: 10px;font-size: 14px;line-height: 20px;}
   }
   
   @media (max-width: 767px){
    .progressbar:after{width: 2px;height: 100%;bottom: -6px;left: 60px;}
    .progressbar{display: block !important;}
    .progressbar li{width: 100%;}
    .left_circle{top: -13px;z-index: 999;left: 52px;width: max-content !important;}
    .right_circle{bottom: -10px;left: 52px;width: max-content !important;top: inherit;}
    .step_counting{position: absolute;top: 32px;left: 42px;margin-top: 0 !important;}
    .steps_box{width: 90px;height: 90px;padding: 24px;}
    .progressbar h5{font-size: 16px;margin: 10px 0 20px 0 !important;}
    section{padding: 30px 0px;}
    .main-heading{font-size: 26px;line-height: 34px;}
    .btn-outline-orange{padding: 12px 16px;}
    .top_content p{margin: 14px 0 !important;}
    h6{font-size: 13px;line-height: 17px;}
    .service_box{min-height: auto;}
    .explore_Sec{display: block !important;margin-top: 11px;}
    .explore_Sec .w-50{width: 100% !important;}
    h2{font-size: 20px;}
    .btn-outline-primary{font-size: 14px;padding: 12px 16px;}
    h3{font-size: 18px;}
    .footer_free_consult h5{font-size: 19px;}
    footer{padding: 30px 0;}
    .footer_end{margin-top: 45px;}
    .footer_btm li a{width: 38px;height: 38px;line-height: 35px;font-size: 18px;}
    .top-banner{min-height: auto;}
    .minus_mt{margin-top: -80px;}
    .mobile_nav{position: relative;z-index: 999;max-height: calc(100vh - 60px);overflow-y: scroll;}
    .btn-outline-primary:hover{padding: 12px 16px;}
    .btn-end{padding: 9px 48px !important;margin-left: 10px;}
    .dropdown-menu h5{font-size: 16px;line-height: 22px;}
    .navbar-custom .dropdown-menu{padding: 18px 0;}
    .mega_menu_links li{margin-bottom: 4px;}
    .navbar-custom .nav-link{position: relative;}
    .dropdown-toggle::after{position: absolute;right: 0; display: none !important;}
  
    .call_mob{min-width: 140px;margin-right: 10px !important;}
    .make-call i{font-size: 25px;margin-right: 5px;}
    .make-call .number{font-size: 13px;}
    .call_details{align-items: center;}
    .call_details .btn-outline-primary{font-size: 13px;padding: 12px 4px;width: 100%;}
    .mob_flex{display: block !important;}
    .data_base{padding-left: 0px;}
    .col_bw_space{--bs-gutter-x: 3rem;}
    .style_head_one .devops_head{margin-top: 0;}
    .style_head_one .top_content .main-heading{font-size: 20px;line-height: 26px;}
    .style_head_one .top_content .punch_text{font-size: 14px;}
    .our_best{padding-bottom: 0;margin-bottom: 0;}
    .facts{position: static;border-radius: 0;padding: 1px 0 10px 0;margin-top: 26px;}
    .counting-numbers h2{line-height: 26px;margin-top: 10px;font-size: 30px;}
    .counting-numbers h5{font-size: 14px;}
    .counting-numbers img{max-width: 30%;}
    .work_icons_sec .work_life img{max-width: 24%;}
    .work_icons_sec .work_life h4{margin-left: 10px !important;font-size: 14px;font-weight: 500;line-height: 20px;}
    .work_icons_sec .work_life.right-border{border-right: 0;}
    .work_icons_sec .work_life .d-block{display: inline !important;}
    .heading_p{font-size: 16px;line-height: 22px;color: #000000;}
    .devops_consulting .punch_text{font-size: 14px;line-height: 22px;margin: 20px 0 !important;}
    .devops_consulting .bottom_head{margin-top: 0;}
    .agile-img{margin-bottom: 40px;}
    img.attachment-full.size-full{max-width: 100%;}
    .servies_wrapper .image-box-content{padding-right: 0;margin-left: 10px;}
    .blog-content-details h4{font-size: 18px;}
    .blog-content.small_blog h2 a{font-size: 20px;}
    .p_m_consulting{background-size: cover;}
    .Custom_breadcum .breadcrumb{font-size: 13px;}
    .technologies_bg .top_content .d-flex.align-items-center.mb-5{margin-bottom: 0 !important;}
    .steps_node::before{display: none;}
    .nj-services-other__inside{padding: 15px;}
    .technologies_bg .top_content .main-heading{margin-top: 0 !important;}
    .node_precess h6{font-size: 14px;font-weight: 500;text-align: center;}
    .node_precess{flex-direction: column !important;}
    .nj-content__icon{margin-right: 0;margin-bottom: 10px;}
    .middel_services .gx_custom{--bs-gutter-x: 1rem !important;}
    .middel_services .h3_title_service{font-size: 16px;margin-bottom: 10px;font-weight: 500;line-height: 22px;margin-top: 15px;}
    .middel_services .heading_p_s{font-size: 14px;line-height: 20px;}
    .middel_services .mb-5{margin-bottom: 28px !important;}
    .nj-services-other__inside .d-flex{display: block !important;}
    .nj-node_box{padding: 15px;}
    .nj-node_box .d-flex{display: block !important;}
    .nj-node_box .w_70{width: 100%;}
    .nj-node_box h6{font-size: 18px;font-weight: 400;line-height: 24px;margin-bottom: 16px !important;}
    .portfolio .blog-content{margin-top: 20px;}
    .success,
    .susscess-content{margin-bottom: 20px;padding-left: 0;}
    .contact-form{margin-top: 0;}
    .contact-form h3{font-size: 26px;}
    .contact-form-propsel{padding: 15px;}
    .pricing-table{padding: 20px;}
    .pricing-table h4{font-size: 20px;margin: 20px 0px;min-height: auto;}
    .pricing-table p{font-size: 16px;line-height: 24px;margin-bottom: 20px;}
    .style_head_one .top_content .p_one{font-size: 14px;line-height: 20px;}
    .heading_top p{max-width: 650px;font-size: 14px;line-height: 20px;}
    .bottom_head{padding: 20px 0 40px;}
    .p1,
    .p2,
    .p3,
    .p4{top: inherit;}
    .steps_line{display: none !important;}
    .steps{display: block !important;text-align: center;padding-bottom: 0;}
    .steps li{margin: 0 10px 20px;display: inline-block;width: calc(65% - 100px);}
    .des_points li{font-size: 14px;line-height: 20px;}
    .ser_part{border-left: 0;margin-top: 10px;}
    .gx_custom{padding-top: 20px;}
    .clients-slider .prev-arrow,
    .clients-slider .next-btn{top: -103px;}
    .footer{padding-top: 0;padding-bottom: 0;font-weight: 100;}
    .rights .d-flex{flex-direction: column;}
    .rights{padding: 8px 0;}
    .rights p,
    .rights a{font-size: 14px;padding: 0px 0;}
    .des_points::before{top: 2px;}
    .top-banner{background-size: cover;background-position: center;}
   
    /* dashboard */
    .school-title{padding: 8px 10px;}
    #user_nav{right: 10px;}
    .school-title h3{font-size: 16px;}
    .dashboard-heading{display: block !important;}
    .dashboard-content{padding-left: 15px;padding-right: 15px;}
    .right-search{margin-top: 15px;}
    .assigment-details{padding: 15px;}
    .btn-right a{margin-bottom: 10px;}
    .alert-msg{margin-top: 0px !important;margin-bottom: 10px !important;}
    .carousel-item img{height: 160px;object-fit: cover;}
    .slider-content{bottom: 2.25rem;}
    .total-items{margin-bottom: 30px;}
    .left-heading h4{font-size: 18px;}
    .left-heading{margin-top: 40px;}
    .estimate .top-heading h5{font-size: 16px;}
    .share a{font-size: 13px;padding: 0px 10px;}
    .referral-content h1{font-size: 24px;}
    .referral-content h1 sup{font-size: 18px;}
    .referral-content{width: 100%;margin-left: 0;}
    .yellow-bg p{font-size: 14px;}
    .referral-content p{font-size: 14px;margin-top: 10px;}
    .circul p{margin-bottom: 30px;font-size: 16px;}
    .referral-book .nav .nav-item .nav-link{margin-right: 5px;}
    .yellow-content{padding: 15px;}
    .wallet-blance .yellow-content h5{font-size: 18px;}
    .wallet-blance .yellow-content p{font-size: 14px;}
    .wallet-blance .yellow-content p{margin-bottom: 20px;}
    .wallet-blance.assignents{padding: 20px 0px 0px;}
    .wallet-blance-inner,
    .right-form{display: block !important;margin-top: 20px;}
    .right-form input{width: 100%;margin-bottom: 10px;}
    .add_money{padding: 20px 20px;}
    .bg-light-purple .mt-50{margin-top: 30px;}
    .add_paise{margin-bottom: 10px;}
    .yellow-band,
    .yellow-band h6{display: block;font-size: 16px;}
    .bulk-content{padding: 70px 20px 20px 20px;}
    .bulk-content .d-flex{display: block !important;}
    .bulk-content ul{margin-bottom: 20px;}
    .bulk-offer .bulk-offer-content .project_icon{left: 37%;top: -41px;}
    .university_main.country_logo{display: block !important;}
    .live_web .dropdown-menu{right: 0;left: auto;}
    .search{width: 100%;justify-content: flex-start;}
    .search a{right: auto;}
    .search input{padding-left: 40px;}
    .right-search .btn.yellow_btn{margin-left: 0px;margin-top: 15px;}
    .se_padg{padding: 30px 0;}
    .reg_instruction li{font-size: 14px;line-height: 20px;margin-bottom: 14px;}
    .t_cont{font-size: 16px;}
    .display-5{font-size: 32px;}
    .btn_contract{padding: 6px 6px;min-width: 136px;}
    .footer_free_consult h3{font-size: 26px;margin-bottom: 10px !important;}
    .luxurycarmarket img{max-width: 70%;}
    .table > :not(caption) > * > *{white-space: nowrap;vertical-align: middle;}
    .step_barcon{overflow-y: hidden;overflow-x: auto;white-space: nowrap;min-width: 306px;}
    .step_barcon ul{display: flex;}
    .step_barcon ul li{min-width: 130px;margin: 0px 8px 6px 4px;}
    .dashboard-content-body h6{font-size: 16px;margin-bottom: 6px !important;}
    .sm_text{line-height: 16px;display: inline-block;}
    .save_exit .btn-outline-primary{font-size: 14px;padding: 12px 10px;}
    .appointment{padding: 30px 0;}
    .appointment p{margin-bottom: 20px;font-size: 14px;}
    .appointment span{display: inline-block !important;line-height: 20px;}
    .appointment h3{font-size: 20px;font-weight: 600;margin-bottom: 10px;}
    .dashboard{min-height: auto;}
    .breadcum-sec{padding: 20px 0;}
    .breadcum-sec ol{font-size: 14px;font-weight: 600;}
    .pricing-area{padding-top: 30px;padding-bottom: 30px;}
    .faq h4{font-size: 16px;}
    .contact_content h4{font-size: 20px;margin-top: 40px;}
   }

   /* toster css
    */
    #toast-container {
        margin-top: 1rem;
      }
      
      #toast-container.toast-top-full-width > .toast {
        max-width: 570px;
      }
      
      #toast-container > .toast-success {
        background-color: #00bb09;
        opacity: 1;
        margin-right: 30px !important;
        width: 400px !important ;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      }
      #toast-container > .toast-warning {
        background-color: #b39800;
        opacity: 1;
        margin-top: 60px;
        width: 400px !important ;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      }
      #toast-container > .toast-error {
        margin-top: 10px;
        margin-right: 10px !important;
        width: 400px !important ;
        background-color: #c00000;
        opacity: 1;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      }

    /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.spinner-box {
  position: fixed;
  left: 50%;
  top: 50%;  
  z-index: 12; 
}


.loading-icon {
  display: flex;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  animation-name: spin;
  animation-duration: 2s;
  transition-timing-function: linear;
  animation-iteration-count: infinite;
  animation: spin 2s linear infinite;
  position: relative;
}

.loading-icon div {
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.react-tel-input .form-control{
  width: 100% !important;
}
.required{
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: red;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.field-icon {
  float: right;
  margin-right: 15px;
  margin-top: -36px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  color: #3565cd;
}

.spinner_overlay{
  background-color: #000;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0.6;
  overflow-y: hidden;
}


#form-file-upload {
  height: 100%;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}



#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.files_type{    width: 25px;
  border-radius: 50%;
  height: 25px;
  position: absolute;
  top: 0;
  right: 60px;
  z-index: 9999;
  opacity: 0;
}
.party_select .dropdown-menu {
  overflow-y: auto;
  max-height: 300px;
}

/* chat-css*/
.bg_10 {    background-color: #fff;
  border-right: 1px solid #ddd;}
.chat_tab .tab-content{border-radius: 0px 10px 10px 0;}
.chat-sec{
  /* min-width: 330px; */
  max-height: 674px;
  border-radius: 10px 0 0 10px;
  overflow:auto;
  display: block;
  height: 50vh;
}
.chat-side-border{     box-shadow: 2px 0px 0px 0px #d9deff8c; }

.top-chat-detail{background-color: #fff;position: fixed;width: 100%;}
.chat_link h3 {
  font-size: 15px;
  color: #333;
  font-weight: 100;
  margin-bottom: 0 !important;
}
.chat_link p{    font-size: 12px;
  color: #777;
  font-weight: 100;}
.chat_link{    cursor: pointer;
  font-weight: 100;
  margin: 0px 0px 0px;
  /* border-top: 1px solid #ddd; */
  padding: 8px 10px;
  border-radius: 0px !important;}
.chat_link .chat_count{    
  /* background: #0071dc; */
  /* width: 20px;
  height: 20px;
  border-radius: 30px; */
  color: #8b8b8b;
  font-size: 10px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-weight: 100;}
.chat-control{background-color: #fff;
  height: 50px;
  color: #777;
  font-size: 15px;
  border: 0;
  /* padding-left: 44px; */
  border: 1px solid #ddd;    font-weight: 500;}
.chat_search_icon{    color: #777;
  font-size: 18px;
  position: absolute;
  left: 16px;
  top: 14px;}
.chat-control:focus {border: 1px solid #ddd;box-shadow: none;}
.chat_search_icon:hover{color: #ffffff;}
.chat_tab .nav-pills .nav-link.active, .chat_tab .nav-pills .show>.nav-link {color: #fff;background-color: #004f99;border-radius: 0;}
.chat_top_sec{background: #ffffff;
  box-shadow: 0px 2px 7px #dfdfdf;
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  padding: 12px 15px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;}

  .dropdown-toggle::after {
    content: none;
}

  .chat-badge-grey{
    font-size: 11px;
    background-color: #00133d !important;
    /* border-color: #b3b3b3; */
    padding: 2px 8px;
    text-transform: uppercase;
    border-radius: 50%;
    color: white;
  }
  .no-msg-found{line-height: 67vh;text-align: center;color: grey;}
.chat-footer{background: #ffffff;border: 1px solid #e8e8e8; font-size: 24px;color: #000000;font-weight: 600;padding: 12px 30px;position: absolute;bottom: 0;width: 100%;}
.chat_tab{background-color: #fff;border-radius: 10px;}
.chat-body{    padding: 75px 15px;height: 674px;overflow: auto;}
.chat_form_control{border: 0;background: transparent;font-size: 16px;color: #000; min-width: 460px;}
.chat_form_control:focus {color: #000000;background-color: transparent;border-color: transparent;box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);}
.bt-send{border-radius: 50px;height: 42px;width: 42px;}
.bt-send:hover{background-color: #00729d;}
.direct-chat-msg {margin-bottom: 10px;    display: flex;
  align-items: flex-start;}
.direct-chat-msg:before, .direct-chat-msg:after {content: " ";display: table;}
.direct-chat-msg:after {clear: both;}
.direct-chat-messages, .direct-chat-contacts {-webkit-transition: -webkit-transform .5s ease-in-out;-moz-transition: -moz-transform .5s ease-in-out;-o-transition: -o-transform .5s ease-in-out;transition: transform .5s ease-in-out;}
.direct-chat-text {border-radius: 5px;
  position: relative;
  background: #e9e9e9;
  border: 0;
  margin: 5px 0 0 50px;
  color: rgb(0, 0, 0);
  max-width: 420px;
  padding: 15px 15px 15px 15px;
  margin-right: 0;
  margin-left: 20px;
  font-size: 14px;
  flex: 0 0 420px;}
.direct-chat-text:after, .direct-chat-text:before {position: absolute;right: 100%;top: 25px;border: solid transparent;border-right-color: #e9e9e9;content: ' ';height: 0;width: 0;pointer-events: none;}

.direct-chat-text:before, .direct-chat-text:after {
  border-width: 10px;
  top: 7px;
}
.right .direct-chat-text {margin-left: 0;
  margin-right: 20px;
  background: #237dd1;
  color: #fff;
  border: 0;
  font-size: 14px;
  padding: 15px 15px 15px 15px;
  max-width: 420px;
  flex: 0 0 420px;text-align: right;
  background: linear-gradient(90deg, rgba(35,125,209,1) 0%, rgba(0,79,153,1) 72%);
}
.right .direct-chat-text:after, .right .direct-chat-text:before {right: auto;left: 100%;border-right-color: transparent;border-left-color: #004f99;}
.direct-chat-img {border-radius: 50%;float: left;}
.right .direct-chat-img {float: right;}
.direct-chat-timestamp {
  font-size: 10px;
    display: block;
    margin-top: 5px;
}
.direct-chat-timestamp-left {color: #5c5c5c;position: absolute;bottom: 10px;left: 15px;font-size: 14px;}
.course_subhead{font-size: 26px;color: #000000;}
.f-clorsg{
  color: #7d7d7d;
}


* {
  scrollbar-width: thin;
  scrollbar-color: #0071DC #DFE9EB;
}

/* Chrome, Edge and Safari */
.chat-sec::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}
.chat-sec::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #DFE9EB;
}

.chat-sec::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

.chat-sec::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

.chat-sec::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #888888;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #888888;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #888888;
}

.react-tel-input .flag-dropdown.open {
  z-index: 3 !important;
}
.chat-image{
  max-width: 100%;
  height: 36px;
  border-radius: 26px;
  width: 36px;
  object-fit: cover;
  object-position: top;
}
.chatimg{
  border-radius: 50%;
  border: 2px solid #fff;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.badge {
  font-size: 12px;
    padding: 6px 12px;
    /* text-transform: uppercase; */
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 13px;
}
.pd-tb-10{
  padding: 10px 0px;
}
.pd-b-10{
  padding: 0px 0px 10px 0px;
}

.algcenter{
  align-items: center;
}
.bb1{
  border-bottom: 1px solid #d0d0d0;
}
.badge-primary{
  background: #0f1451;
  color: white;
}
.bagde-danger{
  background: rgb(179, 5, 5);
  color: white;
}
.bagde-success{
  background: green;
  color: white;
}

 /* reminder-css  */
.noti_box {background-color: #ffffff;padding: 15px;display: flex;align-items: center;box-shadow: 0 0 8px 4px #e7e7e7;justify-content: space-between;}
.noti_box h3 {font-size: 20px;color: #222222;}
.noti_box p {font-size: 14px;color: #555555;line-height: 22px;}
.end_content span {font-size: 14px;color: red;}
.end_content a {font-size: 14px;min-height: 50px;}
.remider_i {width: 70px;height: 70px;background-color: #0071dc;color: #ffffff;border-radius: 50%;display: flex;align-items: center;justify-content: center;font-size: 29px;border: 3px solid #fffc;box-shadow: 1px 1px 2px #cccccc;flex: 0 0 70px;margin-right: 15px;}

.files_upload {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 60px;
  z-index: 9999;
  opacity: 0;
  left: 0;
  border-radius: 15px;
  cursor: pointer;
}

.data_tabed .nav-tabs .nav-link{font-size: 14px;color: #000000;border-bottom: 0;padding: 10px 25px;}
.data_tabed .nav-tabs{border-bottom: 4px solid #e6eff6;}
.data_tabed .nav-tabs .nav-item.show .nav-link, .data_tabed .nav-tabs .nav-link.active{background-color: #e6eff6;border-color: #e6eff6;}
.data_tabed .nav-tabs .nav-link:focus, .data_tabed .nav-tabs .nav-link:hover{border-color: #ffffff;}
.see_more_link{font-size: 16px;text-decoration: underline;font-weight: 500;}
.btn-link{color: #3565cd;font-size: 14px;font-weight: 600;text-decoration: none;padding: 0;display: block;}
.chat_link.active h3{color: #010008;font-weight: 100;}
.chat_link.active p{color: #6e6b6b;font-weight: 100;}

.direct-chat-msg.right{flex-direction: row-reverse;
  justify-content: end;}
  .h6_title, .activity_card p{font-size: 14px;line-height: 18px;}
  .upgrade-btn{padding: 4px 23px;line-height: inherit;height: 35px}

   /* personal_form_fields */
.personal_form_fields .form-control, .personal_form_fields .form-select{border: 2px solid #dddddd;padding: 1rem 0.75rem;}
.personal_form_fields .form-label {margin-bottom: 0.5rem;color: #444444;}
.form-check .form-check-input{float: none;margin-left: 0px;}
.firm_title{color: #444444;margin-bottom: 0;font-size: 18px;font-weight: 500;}
.personal_form_fields .form-check .form-check-input {float: none;margin-left: 10px;margin-top: 13px;width: 18px;height: 18px;margin-top: 2px;border-radius: 0;border-color: #c0bfbf;border-width: 2px;}
.personal_form_fields .form-check {padding-left: 0;margin-right: 80px;}
.personal_form_fields .form-check:last-child{margin-right: 0;}
.personal_form_fields .wrapper{height: 180px;}
.personal_form_fields .signature-pad{width: 623px;height: 202px;}

.pdf_form .modal-dialog{max-width: 1200px;}
.close_icon{position: absolute;top: 10px;right: 10px;}
.css-pdct74-MuiTablePagination-selectLabel{margin-top: 13px;}
.text_white{
  color: white !important;
}
.site{
  color: #2F8380;
}
.custom-chat-head-nav {
  margin-top: 10px;
}
.custom-chat-head-nav > .nav-item > .active{
  background: #1976d238;
}
.custom-chat-head-nav > li{
  width: 50%;
}
.custom-chat-head-nav > .nav-item > .nav-link{
  border: 2px solid #bfd9f2;
    color: #3F338B;
    border-radius: 0px;
    width: 100%;
}
.custom-chat-head-nav > .nav-item > .active {
  background: #1976d238;
}

.custom-chat-head-nav > .nav-item > .nav-link {
  border: 2px solid #bfd9f2;
  color: #3F338B;
  border-radius: 0px;
}
.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}
.activess{
  background: green;
    color: white;
}